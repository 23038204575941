import './bootstrap'

import '../../css/app.css'

import dayjs from 'dayjs';
import dayjsLocaleData from 'dayjs/plugin/localeData';
import dayjsLocaleEt from 'dayjs/locale/et';
import dayjsLocaleRu from 'dayjs/locale/ru';

dayjs.extend(dayjsLocaleData);

import { createApp, DefineComponent, h } from 'vue';
import { createI18n } from 'vue-i18n';
import { SetupCalendar } from 'v-calendar';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../../vendor/tightenco/ziggy/dist/vue.m'
import VueGoogleMaps from '@fawmi/vue-google-maps';
import * as Sentry from "@sentry/vue";

const slavicPluralRules = (choice, choicesLength) => {
    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (!teen && endsWithOne) {
        return (choicesLength < 4) ? 0 : 1;
    }

    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return (choicesLength < 4) ? 1 : 2;
    }

    return (choicesLength < 4) ? 2 : 3;
};

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    pluralRules: {
        ru: slavicPluralRules,
    },
    messages: (() => {
        // const locales = require.context('@Project/Locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
        // const messages: any = import.meta.glob('./Locales/*.json', { eager: true })

        // console.log(messages);

        // const messages = {};

        // locales.keys().forEach((key) => {
        //     const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        //     if (matched && matched.length > 1) {
        //         const locale = matched[1].split('-')[0];

        //         messages[locale] = locales(key);
        //     }
        // });

        const messages: any = {}
        const files: any = import.meta.glob('./Locales/*.json', { eager: true })

        for (const path in files) {
            const locale = path.split('/').pop()?.split('.')[0] as string
            const language = locale.split('-')[0]

            messages[language] = files[path].default
        }

        return messages;
    })(),
});

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const locale = props.initialPage.props.user.preferences.locale;

        i18n.global.locale.value = locale;
        dayjs.locale(locale);

        const inertiaApp = createApp({ render: () => h(App, props) });

        Sentry.init({
            app: inertiaApp,
            dsn: "https://6d1d77536a844c61b56fe4ced02ef394@o79684.ingest.sentry.io/6571430",
            environment: import.meta.env.PROD ? "Production" : "Development",
            integrations: [
                Sentry.browserTracingIntegration(),
            ],
            tracesSampleRate: import.meta.env.PROD ? 0.03 : 0,
            tracePropagationTargets: ["*.pushey.com", "*.pushey.test"],
            logErrors: true,
        });

        inertiaApp
            .use(plugin)
            .use(i18n)
            .use(VueGoogleMaps, {
                load: {
                    key: 'AIzaSyC-qoxcuyx2lril9Xk8ubdTHfFtqVovH60',
                },
            })
            .use(SetupCalendar, {})
            .use(ZiggyVue, Ziggy)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    }
});
